html {
  --vapor-accent-1: #fafafa;
  --vapor-accent-2: #f6f6f6;
  --vapor-accent-3: #efefef;
  --vapor-accent-4: #e8e8e8;
  --vapor-accent-5: #999;
  --vapor-accent-6: #888;
  --vapor-accent-7: #666;
  --vapor-accent-8: #444;
  --vapor-accent-9: #333;
  --vapor-accent-10: #111;

  --vapor-radius-smaller: 3px;
  --vapor-radius-small: 4px;
  --vapor-radius-medium: 7px;
  --vapor-radius-big: 10px;
  --vapor-radius-bigger: 12px;
  --vapor-radius-biggest: 16px;
  --vapor-radius-huge: 20px;

  --vapor-border: solid var(--vapor-accent-4) 1px;
  --vapor-border-highlight: solid var(--vapor-foreground) 1px;
  --vapor-border-primary: solid var(--vapor-primary) 1px;

  --vapor-error-text: #fff;
  --vapor-error: #e00;

  --vapor-primary-text: #fff;
  --vapor-primary-light: #62abff;
  --vapor-primary-dark: #0081f9;
  --vapor-primary: #417ff7;

  --vapor-foreground: #000;
  --vapor-background: #fff;

  --vapor-popover-mobile-background: #fff;
  --vapor-popover-mobile-color: #000;

  --vapor-shadow-smaller: 0px 2px 8px rgba(0, 0, 0, 0.15);
  --vapor-shadow-small: 0 5px 10px rgba(0, 0, 0, 0.12);
  --vapor-shadow-medium: 0 8px 30px rgba(0, 0, 0, 0.12);
  --vapor-shadow-big: 0 30px 60px rgba(0, 0, 0, 0.12);

  --vapor-blanket: rgba(80, 80, 80, 0.4);

  --vapor-black: #000;
  --vapor-white: #fff;

  --vapor-light-yellow: #fed330;
  --vapor-yellow: #f7b731;

  --vapor-light-red: #ff6b6b;
  --vapor-red: #ee5253;

  --vapor-light-orange: #fd9644;
  --vapor-orange: #fa8231;

  --vapor-light-blue: #4b7bec;
  --vapor-blue: #3867d6;

  --vapor-light-aqua: #62abff;
  --vapor-aqua: #2194ff;

  --vapor-light-green: #26de81;
  --vapor-green: #10ac84;

  --vapor-light-teal: #2bcbba;
  --vapor-teal: #0fb9b1;

  --vapor-light-pink: #ffb8b8;
  --vapor-pink: #efafaa;

  --vapor-purple: #a55eea;
}

html.dark-theme {
  --vapor-accent-10: #fafafa;
  --vapor-accent-9: #f6f6f6;
  --vapor-accent-8: #efefef;
  --vapor-accent-7: #e8e8e8;
  --vapor-accent-6: #999;
  --vapor-accent-5: #888;
  --vapor-accent-4: #666;
  --vapor-accent-3: #444;
  --vapor-accent-2: #333;
  --vapor-accent-1: #2a2a2a;
  --vapor-accent-0: #171717;

  --vapor-radius-smaller: 3px;
  --vapor-radius-small: 4px;
  --vapor-radius-medium: 7px;
  --vapor-radius-big: 10px;
  --vapor-radius-bigger: 12px;
  --vapor-radius-biggest: 16px;
  --vapor-radius-huge: 20px;

  --vapor-border-light: solid var(--vapor-accent-1) 1px;
  --vapor-border: solid var(--vapor-accent-3) 1px;
  --vapor-border-highlight: solid var(--vapor-foreground) 1px;
  --vapor-border-primary: solid var(--vapor-primary) 1px;

  --vapor-error-text: #fff;
  --vapor-error: #e00;

  --vapor-primary-text: #fff;
  --vapor-primary-light: #62abff;
  --vapor-primary-dark: #0081f9;
  --vapor-primary: #417ff7;

  --vapor-foreground: #fff;
  --vapor-background: #000;

  --foreground: #fff;
  --background: #000;

  --vapor-popover-mobile-background: #000;
  --vapor-popover-mobile-color: #fff;

  --vapor-shadow-smaller: 0px 2px 8px rgba(100, 100, 100, 0.15);
  --vapor-shadow-small: 0 5px 10px rgba(100, 100, 100, 0.12);
  --vapor-shadow-medium: 0 8px 30px rgba(100, 100, 100, 0.12);
  --vapor-shadow-big: 0 30px 60px rgba(100, 100, 100, 0.12);
  --vapor-tooltip-shadow: 0px 2px 8px rgba(100, 100, 100, 0.15);

  --vapor-blanket: rgba(80, 80, 80, 0.4);

  --vapor-black: #fff;
  --vapor-white: #000;

  --vapor-light-yellow: #fed330;
  --vapor-yellow: #f7b731;

  --vapor-light-red: #ff6b6b;
  --vapor-red: #ee5253;

  --vapor-light-orange: #fd9644;
  --vapor-orange: #fa8231;

  --vapor-light-blue: #4b7bec;
  --vapor-blue: #3867d6;

  --vapor-light-aqua: #62abff;
  --vapor-aqua: #2194ff;

  --vapor-light-green: #26de81;
  --vapor-green: #10ac84;

  --vapor-light-teal: #2bcbba;
  --vapor-teal: #0fb9b1;

  --vapor-light-pink: #ffb8b8;
  --vapor-pink: #f78fb3;

  --vapor-popover-background: #000;
  --vapor-popover-border: var(--vapor-border);

  --bar-background: rgba(80, 80, 80, 0.3);

  color: white;
}

html.dark-theme input {
  color: white;
}

body {
  background: var(--vapor-background);
  color: var(--vapor-foreground);
}
